<template>
  <div class="toOrderMeal">
    <div class="address" @click="toHouseList">
      <div class="icon"><img src="./img/position.png" alt="" /></div>
      <div class="address-t">{{ userInfo.chosenSpacePath }}</div>
      <div class="address-b">{{ userInfo.userName }} {{ userInfo.mobile }}</div>
      <div class="arrow"><img src="./img/more.png" alt="" /></div>
    </div>
    <div class="order">
      <div class="order-t">
        <div class="order-tl">订单信息</div>
        <div class="order-tr" @click="selectTime">
          <div class="txt" v-if="!timeTxt">选择时间</div>
          <div class="txt" v-else>{{ timeTxt }}</div>
          <div class="img"><img src="./img/more.png" alt="" /></div>
        </div>
      </div>
      <div class="order-b">
        <div class="item" v-for="(item, index) in listData" :key="index">
          <div class="item-l"><img :src="item.imageUrls" alt="" /></div>
          <div class="item-r">
            <div class="title">
              {{ item.title }}
            </div>
            <div class="lunch">午餐：{{ item.lunch }}</div>
            <div class="dinner">晚餐：{{ item.dinner }}</div>
            <!-- <div class="des">
              {{ item.shortDesc }}
            </div> -->
            <div class="item-rb">
              <div class="num">
                <div class="minus" v-if="item.num > 0" @click="minus(item)">
                  <img src="./img/minus.png" alt="" />
                </div>
                <div class="txt" v-if="item.num > 0">
                  {{ item.num }}
                </div>
                <div class="add" @click="add(item)">
                  <img src="./img/add.png" alt="" />
                </div>
              </div>
            </div>
            <div class="price">¥{{ item.price }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="total">总计：¥{{ totalPrice }}</div>
    <div class="remark">
      <div class="remark-h3">订单备注</div>
      <div class="main">
        <!-- <p>1、首次订餐需要额外购买送餐用餐盒2份，也可以由用户自行提供餐盒。</p>
        <p>
          2、订餐按照自然月进行预订，自然月每天送餐；如遇特殊情况要取消送餐，请提前一天拨打电话：0574-88387722。
        </p>
        <p>3、根据实际采购，每日会搭配不同菜品。</p> -->
        <v-input
          placeholder="请输入订单备注"
          type="textarea"
          :maxlength="200"
          :showWordLimit="true"
          v-model="orderRemarks"
        ></v-input>
      </div>
    </div>
    <div class="btn" @click="toOrder">立即抢购</div>
    <v-picker
      :columns="statusList"
      :valueShow="pickShow"
      :valueKey="valueKey"
      @clickOverlay="clickPickOverlay"
      @cancel="cancelPick"
      @confirmPicker="confirmPick"
    ></v-picker>
    <v-popup v-model="isPopup">
      <div class="dialog">
        <div class="dialogTitle">选择送达时间</div>
        <div class="dialogMain">
          <div class="dialogMain-l">
            <div
              :class="['item', { active: oIndex == 0 }]"
              @click="selectDate(0)"
            >
              今日
            </div>
            <div
              :class="['item', { active: oIndex == 1 }]"
              @click="selectDate(1)"
            >
              明日
            </div>
          </div>
          <div class="dialogMain-r">
            <div
              v-for="(item, index) in oList"
              :class="[
                'item',
                { active: timeIndex == index },
                { disabled: item.isDis },
              ]"
              :key="index"
              @click="selectoTime(item, index)"
            >
              {{ item.value }}
            </div>
          </div>
        </div>
      </div>
    </v-popup>
  </div>
</template>

<script>
import { toRegister, gloabalCount } from "@/utils/common.js";
import wx from "weixin-js-sdk";
import {
  mealListUrl,
  myCartUrl,
  userInfoUrl,
  toOrderUrl,
  checkIsOrderUrl,
} from "./api.js";
var moment = require("moment");
export default {
  name: "toOrderMeal",
  data() {
    return {
      statusList: [
        {
          value: "午餐",
          key: 1,
        },
        {
          value: "晚餐",
          key: 2,
        },
        // {
        //   value: moment(new Date()).add(2, "months").month() + "月",
        //   key:
        //     moment(new Date())
        //       .add(1, "months")
        //       .startOf("month")
        //       .format("YYYY-MM-DD") +
        //     " " +
        //     "00:00",
        // },
        // {
        //   value: moment(new Date()).add(3, "months").month() + "月",
        //   key:
        //     moment(new Date())
        //       .add(2, "months")
        //       .startOf("month")
        //       .format("YYYY-MM-DD") +
        //     " " +
        //     "00:00",
        // },
        // {
        //   value: moment(new Date()).add(4, "months").month() + "月",
        //   key:
        //     moment(new Date())
        //       .add(3, "months")
        //       .startOf("month")
        //       .format("YYYY-MM-DD") +
        //     " " +
        //     "00:00",
        // },
      ],
      pickShow: false,
      valueKey: "value",
      timeIndex: 3,
      timeTxt: "",
      oList: [
        { value: "12:00", isDis: false },
        { value: "17:00", isDis: false },
      ],
      oIndex: 0,
      isPopup: false,
      finishedText: "",
      finished: false,
      listData: [],
      totalPrice: 0,
      commonOperaParams: {
        showMy: false,
        isCommonOpera: false,
      },
      orderRemarks: "",
      postData: {
        num: 0,
      },
      requestData: {
        curPage: 1,
        pageSize: 10,
        activityCategory: 1,
      },
      cartList: [],
      userInfo: {},
      orderTime: "",
      dateStr: "",
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    this.getToDay();
  },
  async mounted() {
    await this.getCartList();
    // this.getMealList();
    this.getAddress();
    // gloabalCount("", 20, 1);
    console.log(moment(new Date()).add(2, "months").month(), "=-0");
  },
  methods: {
    checkIsOrder() {
      let params = {
        tenantId: this.tenantId,
      };
      this.$axios.get(`${checkIsOrderUrl}`, { params: params }).then((res) => {
        if (res.code == 200) {
          if (res.data.dinner == "1" && res.data.lunch == "1") {
            this.statusList = [
              {
                value: "午餐",
                key: 1,
              },
              {
                value: "晚餐",
                key: 2,
              },
            ];
            this.pickShow = true;
          } else if (res.data.dinner == "1" && res.data.lunch == "0") {
            this.statusList = [
              {
                value: "晚餐",
                key: 2,
              },
            ];
            this.pickShow = true;
          } else if (res.data.dinner == "0" && res.data.lunch == "1") {
            this.statusList = [
              {
                value: "午餐",
                key: 1,
              },
            ];
            this.pickShow = true;
          } else if (res.data.dinner == "0" && res.data.lunch == "0") {
            this.statusList = [];
            this.$toast("当前时间不可预约！");
          }
        }
      });
    },
    getToDay() {
      let date = new Date();
      let year = date.getFullYear();
      let month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      this.dateStr = `${year}-${month}-${day}`;
    },
    clickPickOverlay() {
      this.pickShow = false;
    },
    cancelPick() {
      this.pickShow = false;
    },
    confirmPick(oValue) {
      console.log(oValue);
      this.pickShow = false;
      this.timeTxt = oValue.value;
      this.orderTime = oValue.key;
    },
    returnDis() {
      this.oList.forEach((item, index) => {
        console.log(item, index, 998);
        let oTime = moment(new Date()).format("YYYY-MM-DD") + " " + "12:00:00";
        let centerTime = new Date(oTime).getTime();
        let oTime1 = moment(new Date()).format("YYYY-MM-DD") + " " + "17:00:00";
        let lastTime = new Date(oTime1).getTime();
        if (this.oIndex == 0) {
          if (index == 0) {
            if (new Date().getTime() >= centerTime) {
              item.isDis = true;
            }
          }
          if (index == 1) {
            if (new Date().getTime() >= lastTime) {
              item.isDis = true;
            }
          }
        } else {
          item.isDis = false;
        }
      });
    },
    toHouseList() {
      wx.miniProgram.redirectTo({
        url: `/subPages/my/myRoom?isFromRes=1`,
      });
    },
    async toOrder() {
      let numArr = [];
      let idArr = [];
      this.listData.forEach((item) => {
        if (item.num && item.num != 0) {
          numArr.push(item.num);
          idArr.push(item.id);
        }
      });
      if (numArr.length == 0) {
        this.$toast({ message: "请选择菜品", duration: 2000 });
        return;
      }
      if (!this.orderTime) {
        this.$toast({ message: "请选择用餐时间", duration: 2000 });
        return;
      }
      let params = {
        houseId: this.roomId,
        mealIds: idArr.join(","),
        mealNums: numArr.join(","),
        orderRemarks: this.orderRemarks,
        // orderTime: this.orderTime,
        type: this.orderTime,
        receiverAddress: this.userInfo.chosenSpacePath,
        receiverName: this.userInfo.userName,
        receiverPhone: this.userInfo.mobile,
        userId: this.userId,
      };
      let res = await this.$axios.post(
        `${toOrderUrl}`,
        this.$qs.stringify(params)
      );
      if (res.code === 200) {
        this.$toast({ message: "下单成功", duration: 2000 });
        this.$router.replace({
          name: "orderMealSuccess",
        });
      }
    },
    async getAddress() {
      let params = {
        userId: this.userId,
      };
      let res = await this.$axios.get(`${userInfoUrl}`, { params: params });
      if (res.code === 200) {
        this.userInfo = res.data;
      }
    },
    calTime() {
      if (this.oIndex == 0) {
        if (this.timeIndex == 0) {
          this.timeTxt = "今日 12:00";
          this.orderTime =
            moment(new Date()).format("YYYY-MM-DD") + " " + "12:00";
        } else {
          this.timeTxt = "今日 17:00";
          this.orderTime =
            moment(new Date()).format("YYYY-MM-DD") + " " + "17:00";
        }
      } else {
        if (this.timeIndex == 0) {
          this.timeTxt = "明日 12:00";
          this.orderTime =
            moment(new Date()).add(1, "days").format("YYYY-MM-DD") +
            " " +
            "12:00";
        } else {
          this.timeTxt = "明日 17:00";
          this.orderTime =
            moment(new Date()).add(1, "days").format("YYYY-MM-DD") +
            " " +
            "17:00";
        }
      }
    },
    selectoTime(item, index) {
      if (!item.isDis) {
        this.timeIndex = index;
        this.calTime();
        this.isPopup = false;
      }
    },
    selectDate(data) {
      this.oIndex = data;
      this.timeIndex = 3;
      this.returnDis();
    },
    selectTime() {
      // this.returnDis();
      this.checkIsOrder();
      // this.pickShow = true;
    },
    async getCartList() {
      let params = {
        userId: this.userId,
        day: this.dateStr,
      };
      let res = await this.$axios.get(`${myCartUrl}`, { params: params });
      if (res.code === 200) {
        this.listData = res.data;
        console.log(this.cartList, 9876);
        this.listData.forEach((ele) => {
          ele.num = ele.mealNum;
        });
        this.changePrice();
      }
    },
    getMealList() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
      };
      this.$axios.get(`${mealListUrl}`, { params: params }).then((res) => {
        console.log(res, 333);
        if (res.code === 200) {
          if (res.data.pages <= res.data.current) {
            this.finished = true;
          } else {
            this.$refs.list._data.loading = false;
          }
          this.finishedText = "";
          if (res.data.records.length === 0) {
            this.finishedText = "";
            return;
          }
          res.data.records.forEach((item) => {
            if (this.cartList.length != 0) {
              this.cartList.forEach((ele) => {
                if (item.id == ele.id) {
                  item.num = ele.mealNum;
                }
              });
            } else {
              item.num = 0;
            }

            this.listData.push(item);
            this.changePrice();
          });
        } else {
        }
      });
    },
    changePrice() {
      let total = 0;
      this.listData.forEach((item) => {
        console.log(item, "+++++++++++");
        if (item.num && item.num != 0) {
          total += Number(item.num) * Number(item.price);
        }
      });
      this.totalPrice = total.toFixed(2);
    },
    add(item) {
      item.num++;
      this.changePrice();
    },
    minus(item) {
      item.num--;
      this.changePrice();
    },
  },
};
</script>
<style lang="less" scoped>
.toOrderMeal {
  min-height: 100vh;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  padding: 22px 30px 0;
  .address {
    padding: 34px 134px 10px 66px;
    background: #ffffff;
    box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
    margin-bottom: 30px;
    position: relative;
    .icon {
      width: 42px;
      height: 34px;
      position: absolute;
      top: 42px;
      left: 18px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .arrow {
      width: 48px;
      height: 48px;
      position: absolute;
      top: 20px;
      right: 18px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .address-t {
      font-size: 36px;
      font-weight: 700;
      color: #151c39;
      line-height: 50px;
      margin-bottom: 20px;
    }
    .address-b {
      font-size: 28px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.25);
      line-height: 40px;
    }
  }
  .order {
    padding: 40px 8px 30px 16px;
    background: #ffffff;
    box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
    min-height: 294px;
    .order-t {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 44px;
      .order-tl {
        font-size: 32px;
        font-weight: 600;
        color: #151c39;
        line-height: 44px;
      }
      .order-tr {
        display: flex;
        align-items: center;
        .txt {
          font-size: 28px;
          font-weight: 600;
          color: #007eff;
          line-height: 40px;
        }
        .img {
          width: 48px;
          height: 48px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .order-b {
      .item {
        display: flex;
        min-height: 136px;
        position: relative;
        margin-bottom: 50px;
        .item-l {
          width: 136px;
          border-radius: 4px;
          margin-right: 12px;
          display: flex;
          align-items: center;
          img {
            width: 100%;
            height: 136px;
            border-radius: 4px;
          }
        }
        .item-r {
          flex: 1;
          min-width: 0;
          .lunch,
          .dinner {
            font-size: 28px;
            color: rgba(0, 0, 0, 0.5);
          }
          .title {
            font-size: 28px;
            font-weight: 400;
            color: #151c39;
            line-height: 40px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-bottom: 10px;
          }
          .des {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 28px;
            font-weight: 400;
            color: #bfbfbf;
            line-height: 40px;
          }
          .orderNum {
            font-size: 28px;
            font-weight: 400;
            color: #b4b4b4;
            line-height: 40px;
            margin-bottom: 42px;
          }
          .item-rb {
            display: flex;
            justify-content: space-between;
            align-content: center;
            padding-right: 10px;
            position: relative;

            .num {
              display: flex;
              align-content: center;
              position: absolute;
              // bottom: 0;
              right: 0;
              .minus,
              .add {
                width: 50px;
                height: 50px;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
              .txt {
                margin: 0 18px;
                font-size: 40px;
                font-weight: 400;
                color: #151c39;
                line-height: 56px;
              }
            }
          }
          .price {
            font-size: 28px;
            font-weight: 600;
            color: #151c39;
            line-height: 40px;
            position: absolute;
            top: 0;
            right: 10px;
          }
        }
      }
    }
  }
  .total {
    font-size: 36px;
    font-weight: 600;
    color: #151c39;
    line-height: 50px;
    margin: 32px 0 54px;
    text-align: right;
  }
  .remark {
    .remark-h3 {
      font-size: 32px;
      font-weight: 600;
      color: #151c39;
      line-height: 44px;
      margin-bottom: 32px;
    }
    .main {
      border-radius: 16px;
      // /deep/ .van-field {
      //   height: 320px;
      //   background: #f7f8fa;
      //   border-radius: 16px;
      // }
      p {
        font-size: 26px;
        font-weight: 400;
        color: #000;
        line-height: 32px;
        margin-bottom: 10px;
      }
      /deep/ .van-field {
        background: #f7f8fa;
        border-radius: 16px;
      }
    }
  }
  .btn {
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #72a8ff 0%, #3680ff 100%);
    border-radius: 10px;
    font-size: 32px;
    font-weight: 600;
    color: #ffffff;
    line-height: 66px;
    text-align: center;
    margin: 100px auto 0;
  }
  .dialog {
    height: 100%;
    display: flex;
    flex-direction: column;
    .dialogTitle {
      font-size: 32px;
      text-align: center;
      font-weight: 400;
      color: #151c39;
      line-height: 44px;
      padding: 30px 0;
      border-bottom: 2px solid #e9e9e9;
    }
    .dialogMain {
      flex: 1;
      display: flex;

      .dialogMain-l {
        width: 240px;
        background: #f6f6f6;
        .item {
          padding: 24px 0;
          text-align: center;
          font-size: 32px;
          font-weight: 400;
          color: #b4b4b4;
          line-height: 44px;
        }
        .active {
          font-weight: 700;
          background: #fff;
          color: #151c39;
        }
      }
      .dialogMain-r {
        flex: 1;
        .item {
          padding: 24px 0 24px 22px;
          font-size: 32px;
          font-weight: 400;
          color: #151c39;
          line-height: 44px;
          border-bottom: 2px solid #e9e9e9;
          position: relative;
        }
        .active {
          font-weight: 700;
          color: #007eff;
          &::after {
            content: "";
            position: absolute;
            background: url("./img/sure.png") no-repeat;
            background-size: 100% 100%;
            width: 32px;
            height: 24px;
            right: 42px;
            top: 0;
            bottom: 0;
            margin: auto;
          }
        }
        .disabled {
          color: #999;
        }
      }
    }
  }
}
</style>
<style lang="less">
.toOrderMeal {
}
</style>
