//点餐套餐
const mealListUrl = `/gateway/hc-health/meal/mealList`;
//用户加入
const addCartUrl = `/gateway/hc-health/meal/addGoodsCart`;
//购物车
const myCartUrl = `/gateway/hc-health/meal/myGoodsCart`;
//下单
const toOrderUrl = `/gateway/hc-health/meal/submitOrder`;
//我的订单
const myOrderUrl = `/gateway/hc-health/meal/myOrderList`;
//取消订单
const cancelOrderUrl = `/gateway/hc-health/meal/cancelOrder`;
//确认收货
const sureOrderUrl = `/gateway/hc-health/meal/confirmReceive`;
//确认接单
const sureAcceptUrl = `/gateway/hc-health/meal/orderSend`;
//商家订单列表
const bussiOrderUrl = `/gateway/hc-health/meal/allOrderList`;

//电话列表
const isTelShow = `/gateway/blade-system/application/getFunctionSetting`;
//房号列表
const roomListUrl = `/gateway/hc-mini/user/mini/user-asset-list`;
//用户信息
const userInfoUrl = `/gateway/hc-mini/user/mini/user-info`;
//返回当前可预约时间
const checkIsOrderUrl = `/gateway/hc-health/meal/checkIsOrder`;
export {
  mealListUrl,
  roomListUrl,
  userInfoUrl,
  addCartUrl,
  myCartUrl,
  toOrderUrl,
  myOrderUrl,
  cancelOrderUrl,
  sureOrderUrl,
  sureAcceptUrl,
  isTelShow,
  bussiOrderUrl,
  checkIsOrderUrl,
};
